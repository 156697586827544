<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Justificaciones</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="8">
        <v-text-field
          v-model="justListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col style="align-self: center; text-align: right;" cols="4">
        <v-btn
          depressed
          color="default-btn primary"
          @click="createJustDialog = true, isCreateOrUpdate = true"
        >
          Agregar justificación
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
          Configuración de las justificaciones de cancelado que se podrán usar cuando se cancele un contrato.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <h5>Texto</h5>
      </v-col>
      <v-col cols="4">
        <h5>Usuario mod</h5>
      </v-col>
      <v-col cols="3">
        <h5>Última actualización</h5>
      </v-col>
      <v-col cols="1">
        <h5>Acción</h5>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="justList"
      :search="justListSearch"
      loading-text="Cargando"
      :loading="justListLoader"
      no-data-text="No hay justificaciones creadas"
      :items-per-page="8"
      :footer-props="{itemsPerPageOptions:[8, 16, -1]}"
    >
      <template v-slot:default="props">
          <div v-for="(justItem, index) in props.items" :key="index">
          <v-row :class="index%2 === 0 ? 'backWhite' : 'backGray'">
            <v-col cols="4">
              <span> {{ justItem.text }} </span>
            </v-col>
            <v-col cols="4">
              <span> {{ justItem.usermod }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ justItem.updated }} </span>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editJustification(justItem)"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          </div>
      </template>
  </v-data-iterator>
    <v-dialog
      max-width='500px'
      v-model="createJustDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center-dialog centered-dialog__card">
          <v-icon class="mx-1">mdi-clipboard-text</v-icon>
          <h4>{{ getCreateOrEdit() }} Justificación</h4>
          <v-icon class="mx-1">mdi-clipboard-text</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="justForm" v-model="justForm.isValid">
            <v-row no-gutters>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="name">
                  Texto
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="name"
                  outlined
                  single-line
                  background-color="white"
                  v-model="justForm.text"
                />
              </v-col>
              <!-- <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="email">
                  Email
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="email"
                  outlined
                  single-line
                  background-color="white"
                  v-model="justForm.email"
                />
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :disabled="!justForm.isValid"
                :loading="createOrEditBtnLoader"
                @click="createOrUpdateJustification()"
              >
                {{ getCreateOrEdit() }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Justificaciones',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      justList: [],
      justListSearch: '',
      justListLoader: false,
      createJustDialog: false,
      justForm: {
        text: '',
        email: '',
        id: '',
        isValid: ''
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false
    }
  },

  created () {
    this.justListLoader = true
    this.getJustifications()
  },

  computed: {
  },

  methods: {
    ...mapActions('administration', ['getAdminJustifications', 'addAdminJustification', 'updateAdminJustification']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getJustifications () {
      this.getAdminJustifications()
        .then(response => {
          this.justListLoader = false
          this.justList = response.data
          this.closeDialog()
        })
        .catch(() => {
          this.justListLoader = false
        })
    },

    editJustification (item) {
      this.isCreateOrUpdate = false
      this.justForm.id = item.idjustification
      this.justForm.name = item.text
      this.createJustDialog = true
    },

    createOrUpdateJustification () {
      this.createOrEditBtnLoader = true
      if (this.isCreateOrUpdate) {
        const request = {
          text: this.justForm.text
        }
        this.addAdminJustification(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getJustifications()
            this.setSnackbar({
              text: 'Justificación registrada correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
          .catch(() => {
            this.createOrEditBtnLoader = false
            this.setSnackbar({
              text: 'No se pudo crear la justificación',
              timeout: 5000,
              showing: true,
              color: colors.error
            })
          })
      } else {
        const request = {
          id: this.justForm.id,
          text: this.justForm.text
        }
        this.updateAdminJustification(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getJustifications()
            this.setSnackbar({
              text: 'Justificación actualizada correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
      }
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    closeDialog () {
      this.createJustDialog = false
      this.$refs.justForm.resetValidation()
      this.justForm = {
        text: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

</style>
